.switch-button {
  background: #DDDCFA;
  border-radius: 30px;
  overflow: hidden;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #000;
  position: relative;
  padding: 5px 155px 5px 5px;
  position: relative;

  &:before {
    content: "Annually";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }

  &-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked+.switch-button-label:before {
      transform: translateX(150px);
      transition: transform 300ms linear;
    }

    &+.switch-button-label {
      position: relative;
      padding: 5px 0;
      width: 150px;
      display: block;
      user-select: none;
      pointer-events: none;

      &:before {
        content: "";
        background: #fff;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 30px;
        transform: translateX(0);
        transition: transform 300ms;
      }

      .switch-button-label-span {
        position: relative;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .switch-button {
    font-size: 16px;
  }
}