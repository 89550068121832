.card {
    position: relative;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    background-color: #FFF;
    border-radius: 10px;
    overflow: hidden;

    .shimmerBG {
        animation-duration: 2.2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: shimmer;
        animation-timing-function: linear;
        background: #ddd;
        background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
        background-size: 1200px 100%;
    }


    @-webkit-keyframes shimmer {
        0% {
            background-position: -100% 0;
        }

        100% {
            background-position: 100% 0;
        }
    }

    @keyframes shimmer {
        0% {
            background-position: -1200px 0;
        }

        100% {
            background-position: 1200px 0;
        }
    }

    .media {
        height: 130px;
    }

    .p-20 {
        padding: 0 20px;
    }

    .content-line {
        height: 9px;
        width: 100%;
        margin-bottom: 15px;
        border-radius: 8px;
    }

    .list-content-line {
        height: 8px;
        width: 100%;
        margin-bottom: 15px;
        border-radius: 8px;
    }

    .mobile-content-line {
        height: 8px;
        width: 100%;
        margin: 15px 0;
        border-radius: 8px;
    }

    .m-t-12 {
        margin-top: 12px;
    }

    .m-t-16 {
        margin-top: 16px;
    }

}