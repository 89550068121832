.rg-pane-top .rg-cell-focus,
.rg-pane-top .rg-touch-fill-handle {
  display: none !important;
}
.rg-pane-top .rg-partial-area-selected-range {
  display: none !important;
}
.last-index {
  width: -webkit-fill-available !important;
}
