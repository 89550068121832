.md-tableview-container,
.md-table-container {
  width: 90%;
  margin: 2rem auto;
  // max-height: 600px;
  overflow: auto;

  .rg-chevron-cell {
    p {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .rg-chevron-cell.expanded.name {
    p {
      max-width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .rg-chevron-cell.collapsed.name {
    p {
      max-width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell {
    background-color: #796cd6;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label input:checked ~ span {
    background-color: transparent;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label:hover input ~ span {
    background-color: transparent;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell span {
    position: static;
    background-color: transparent;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label input:checked ~ span:after {
    display: none;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell {
    background-color: #796cd6;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label input:checked ~ span {
    background-color: transparent;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label:hover input ~ span {
    background-color: transparent;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell span {
    position: static;
    background-color: transparent;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label input:checked ~ span:after {
    display: none;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell {
    background-color: #796cd6;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label input:checked ~ span {
    background-color: transparent;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label:hover input ~ span {
    background-color: transparent;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell span {
    position: static;
    background-color: transparent;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label input:checked ~ span:after {
    display: none;
  }

  .reactgrid {
    font-family: Arial, Helvetica, sans-serif;
    // max-height: 600px;
  }

  .reactgrid-content {
    // max-height: 600px;
  }

  .rg-celleditor {
    color: black !important;
    padding: 0 !important;

    input {
      padding: 0 1rem;
      color: white;
      background-color: rgb(66, 60, 130);
    }
  }

  .invalid {
    background-color: #ffa5a5 !important;
  }

  .rg-pane-left {
    z-index: 1;
    background-color: #796cd6 !important;

    .rg-touch-fill-handle {
      display: none;
    }
  }

  .rg-pane-left .rg-header-cell:first-child {
    border-radius: 10px 0 0 0;
  }

  .rg-pane-right {
    background-color: #796cd6 !important;
    box-shadow: -2px -2px 3px 1px rgba(0, 0, 0, 0.06);

    .rg-touch-fill-handle {
      display: none;
    }

    .rg-cell-focus {
      display: none;
    }
  }

  .rg-pane-bottom {
    background-color: #796cd6 !important;

    .rg-touch-fill-handle {
      display: none;
    }

    .rg-cell-focus {
      display: none;
    }
  }

  .rg-pane-top.rg-pane-right {
    border: none !important;
    border-left: 1px rgba(255, 255, 255, 0.3) solid;

    .header-cell.rg-cell {
      border-right: none !important;
      background-color: #4bb6b9 !important;
    }
  }

  // top
  .rg-pane-top {
    border-top-left-radius: 10px;
    background-color: #4bb6b9 !important;
    z-index: 2;

    .rg-touch-fill-handle {
      display: none;
    }

    .rg-cell-focus {
      display: none;
    }
  }

  .rg-cell-nonEditable {
    background-color: #867bd7 !important;
  }

  .rg-filter-cell {
    background-color: #4bb6b9 !important;
  }

  .rg-text-cell {
    background-color: #796cd6;

    p {
      max-width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .header-cell {
    color: white !important;
    background-color: #4bb6b9 !important;
    padding: 0 1rem !important;
    border-top: none;
    border-right: 1px #e2e8f0 solid;
  }

  .header-cell.rg-cell {
    border-left: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-right: 1px #e8e8e8 solid;
  }

  .reactgrid-content .rg-pane-shadow.shadow-right {
    display: none;
  }

  .rg-cell:not(.header-cell, .footer, .rg-addRow-cell, .rg-dropdown-cell, .rg-date-cell, .name) {
    color: white;
    padding: 0.9rem 0.9rem 0.5rem 0.9rem !important;
    background-color: #796cd6;
  }

  .rg-date-cell {
    color: #796cd6;
    padding: 0 !important;
    background-color: #796cd6;
  }

  .rg-dropdown-cell > div {
    width: inherit !important;
  }

  .rg-dropdown-cell.last-index > div {
    width: 174px !important;
  }

  .rg-dropdown-cell {
    color: white;
    background-color: #796cd6;

    button {
      width: 100%;
    }

    .rg-dropdown-option.focused {
      color: #1a202c !important;
    }

    .rg-dropdown-option {
      color: #1a202c !important;
      padding: 0.5rem 1.3rem !important;
    }

    .rg-dropdown-menu {
      border-radius: 10px !important;
      color: #1a202c;
    }

    .css-1sl80az-indicatorSeparator {
      display: none;
    }

    .paddingLeft {
      padding-left: 3rem !important;
    }
  }

  .tableview-add {
    color: black;
    padding: 1rem;
  }

  .rg-context-menu {
    color: black;
  }

  .shadow-top {
    box-shadow: none !important;
  }

  .reactgrid-content .rg-pane-shadow.shadow-top-left-corner {
    z-index: 3;
    background-color: #796cd6 !important;

    .rg-cell {
      border-radius: 10px 0 0 0;
    }
  }

  .reactgrid-content .rg-pane-shadow.shadow-top-right-corner {
    background-color: #796cd6 !important;

    .rg-cell {
      border-radius: 0 10px 0 0;
    }
  }

  .reactgrid-content .rg-pane-shadow.shadow-bottom-left-corner {
    box-shadow: none !important;
    background-color: #796cd6 !important;

    .rg-cell {
      border-radius: 0 0 0 10px;
    }
  }

  .reactgrid-content .rg-pane-shadow.shadow-bottom-right-corner {
    box-shadow: none !important;
    background-color: #796cd6 !important;

    .rg-cell {
      border-radius: 0 0 10px 0;
    }
  }

  .rg-context-menu-option {
    font-size: 14px;
  }

  .reactgrid-content .rg-pane .rg-partial-area.rg-partial-area-selected-range {
    background-color: rgba(214, 212, 212, 0.3) !important;
    border: 1px #838383 solid !important;
  }

  .rg-fill-handle {
    background-color: #838383 !important;
  }

  .rg-celleditor {
    border: 1px #646464 solid !important;
  }

  .rg-cell {
    border-color: rgba(255, 255, 255, 0.3) !important;
  }

  .shadow-bottom-left-corner > div {
    border-radius: 0 0 0 10px;
  }

  .rg-addRow-cell {
    background-color: #796cd6 !important;
    border-radius: 0 0 0 10px;
  }

  .rg-header-cell {
    background-color: #4bb6b9 !important;
  }

  .rg-pane-right .rg-header-cell:first-child {
    border-radius: 0 10px 0 0;
  }

  .css-smtpzp-control {
    height: 100% !important;
    width: 100% !important;
  }

  .css-12htazg-ValueContainer {
    width: 100% !important;
  }

  // dropdown
  .rg-dropdown-menu {
    background-color: #fff;
    color: black;
  }

  // date cell
  .rg-date-cell {
    .react-datepicker-wrapper {
      position: relative;
    }

    .react-datepicker__tab-loop {
      position: fixed;
      z-index: 2;
    }

    .react-datepicker-wrapper {
      height: 100%;
    }

    .react-datepicker__input-container {
      height: 100%;

      input {
        height: 100%;
        width: 100%;
        background-color: transparent;
        color: black;
      }
    }

    .chakra-button__icon {
      margin-left: auto;
    }
  }

  .rg-celleditor.rg-date-celleditor {
    display: none;
  }

  .dimension .react-datepicker-popper {
    transform: translate(-40px, 46px) !important;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-dropdown-cell .rg-dropdown-option.selected::before {
    content: '';
  }

  .header-first {
    border-top-left-radius: 10px;
  }

  .header-select {
    border-top-left-radius: 10px;
    background-color: #4bb6b9 !important;
    padding: 1rem 0.9rem 0.5rem 0.9rem !important;
  }

  .header-last {
    border-top-right-radius: 10px;
  }

  .footer {
    background-color: #796cd6 !important;
  }

  .footer-first {
    border-bottom-left-radius: 10px;
    background-color: #796cd6 !important;
  }

  .footer-last {
    border-bottom-right-radius: 10px;
    background-color: #796cd6 !important;
  }
}

.datecell .react-datepicker-popper {
  position: fixed !important;
}
