.HW_badge {
  /* color: #212121 !important; */
  transform: none !important;
  transition: none !important;
  opacity: 1 !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 9 !important;
  font-size: 14px !important;
  /* background-color: transparent !important; */
  display: flex !important;
  align-items: center !important;
  /* font-weight: 900 !important; */
  white-space: nowrap;
  font-weight: normal !important;
  font-family: Roboto Regular;
}
.HW_badge_cont {
  /* height: 32px !important;
  width: 70px !important; */
  visibility: visible !important;
}
.HW_badge_cont.HW_visible {
  opacity: 0 !important;
}
