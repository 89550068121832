.nav-switch {
  height: 17px;
  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 17px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 20px;
    left: 0;
    top: 1px;
    bottom: 4px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .icon::before {
    background-image: url('../../assets/toggle_after_icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(45deg);
  }

  .slider.w-color:before {
    background-color: #d9d9d9;
  }

  .slider.w-color {
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider.w-color {
    background-color: #ccc;
  }

  input:checked + .slider.icon {
    background-color: #d9d9d9;
  }

  .slider.icon:before {
    background-image: url('../../assets/toggle_Icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .disabled-toggle {
    opacity: 0.5;
    cursor: default;
  }
}
