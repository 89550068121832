.md-block-table-container {
  width: 100% !important;
  max-width: 100% !important;
  padding-bottom: 1.5rem !important;

  .reactgrid {
    // min-width: 100% !important;
    font-family: Arial, Helvetica, sans-serif;
    max-height: 600px;
  }

  .dimension-cell{
    background-color: #796cd6 !important;
  }

  .reactgrid-content {
    // min-width: 100% !important;
    max-height: 600px;
  }

  p {
    font-size: 12px !important;
  }

  //cell
  .rg-cell {
    border-color: rgba(255, 255, 255, 0.3) !important;
    background-color: #796cd6;
  }

  .rg-celleditor {
    border: 1px #646464 solid !important;
    padding: 0 !important;

    input {
      padding: 0 1rem;
      background-color: rgb(66, 60, 130);
    }
  }

  .rg-cell:not(.header-cell, .footer, .rg-addRow-cell, .rg-dropdown-cell, .rg-date-cell, .name) {
    color: white;
    padding: 0 8px !important;
    background-color: transparent;
  }

  //header-cell
  .header-cell {
    color: white !important;
    background-color: #4bb6b9 !important;
    padding: 0 8px !important;
    border-top: none;
    border-right: 1px #e2e8f0 solid;
  }

  .header-first {
    border-top-left-radius: 10px;
  }

  .header-last {
    border-top-right-radius: 10px;
  }

  .header-cell.rg-cell {
    border-left: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-right: 1px #e8e8e8 solid;
  }

  //chevron-cell
  .rg-chevron-cell {
    p {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .rg-chevron-cell.expanded.name {
    p {
      max-width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .rg-chevron-cell.collapsed.name {
    p {
      max-width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .chevron-child {
    color: #c9c7df !important;
  }

  //text-cell
  .rg-text-cell {
    p {
      max-width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .reactgrid-content .rg-pane .rg-cell {
    justify-content: center;
  }

  //pane
  .rg-pane {
    background-color: transparent !important;
  }

  //pane-top
  .rg-pane-top {
    border-top-left-radius: 10px;
    background-color: #4bb6b9 !important;
    z-index: 1;

    .rg-touch-fill-handle {
      display: none;
    }

    .rg-cell-focus {
      display: none;
    }
  }

  .rg-pane-top.rg-pane-right {
    border: none !important;
    border-left: 1px rgba(255, 255, 255, 0.3) solid;

    .header-cell.rg-cell {
      border-right: none !important;
    }
  }

  //pane-right
  .rg-pane-right {
    box-shadow: -2px -2px 3px 1px rgba(0, 0, 0, 0.06);

    .rg-touch-fill-handle {
      display: none;
    }

    .rg-cell-focus {
      display: none;
    }
  }

  .rg-pane-right .rg-header-cell:first-child {
    border-radius: 0 10px 0 0;
  }

  //pane-bottom
  .rg-pane-bottom {
    .rg-touch-fill-handle {
      display: none;
    }

    .rg-cell-focus {
      display: none;
    }
  }

  //pane-left
  .rg-pane-left {
    z-index: 1;

    .rg-touch-fill-handle {
      display: none;
    }
  }

  .rg-pane-left .rg-header-cell:first-child {
    border-radius: 10px 0 0 0;
  }

  //others
  .invalid {
    background-color: #ffa5a5 !important;
  }

  .css-smtpzp-control {
    height: 100% !important;
    width: 100% !important;
  }

  .css-12htazg-ValueContainer {
    width: 100% !important;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-dropdown-cell .rg-dropdown-option.selected::before {
    content: '';
  }

  .reactgrid-content .rg-pane-shadow.shadow-right {
    display: none;
  }

  // .tableview-add {
  //   color: black;
  //   padding: 1rem;
  // }

  // .rg-context-menu {
  //   color: black;
  // }

  .shadow-top {
    box-shadow: none !important;
  }

  .reactgrid-content .rg-pane-shadow.shadow-top-left-corner {
    z-index: 2;

    .rg-cell {
      border-radius: 10px 0 0 0;
    }
  }

  .reactgrid-content .rg-pane-shadow.shadow-top-right-corner {
    .rg-cell {
      border-radius: 0 10px 0 0;
    }
  }

  .reactgrid-content .rg-pane-shadow.shadow-bottom-left-corner {
    box-shadow: none !important;

    .rg-cell {
      border-radius: 0 0 0 10px;
    }
  }

  .reactgrid-content .rg-pane-shadow.shadow-bottom-right-corner {
    box-shadow: none !important;

    .rg-cell {
      border-radius: 0 0 10px 0;
    }
  }

  // .rg-context-menu-option {
  //   font-size: 14px;
  // }

  .reactgrid-content .rg-pane .rg-partial-area.rg-partial-area-selected-range {
    background-color: rgba(214, 212, 212, 0.3) !important;
    border: 1px #838383 solid !important;
  }

  .rg-fill-handle {
    background-color: #838383 !important;
  }

  .shadow-bottom-left-corner > div {
    border-radius: 0 0 0 10px;
  }

  .border-bottom {
    border-bottom-left-radius: 10px;
  }

  .border-right:last-of-type {
    border-bottom-right-radius: 10px;
  }

  // @media screen and (max-height: 820px) {
  //   .reactgrid {
  //     max-height: 350px;
  //   }
  // }
}

.cell-disabled {
  cursor: auto !important;
}

.non-edit-bg.rg-cell {
  background-color: #453d85 !important;
}
