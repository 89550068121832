table {
    border-collapse: separate !important;
    border-spacing: 0;

  }
  
  td {
    border:'1px solid rgba(255, 255, 255, 0.30)';
    border-style: none solid solid none;
    padding: 10px;
border-top: none !important;

  }
  tbody tr  {
border-top: none !important;
  }
  
  tr:first-child th:first-child { border-top-left-radius: 10px; }
  tr:first-child th:last-child { border-top-right-radius: 10px; }
  
  tr:last-child td:first-child { border-bottom-left-radius: 10px; }
  tr:last-child td:last-child { border-bottom-right-radius: 10px; }
  
  tr:first-child td { border-top-style: solid; }
  tr td:first-child { border-left-style: solid; }