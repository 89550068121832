.editor-container {
  position: relative;
  width: 100%;
  margin: '10px';
}
.editor-container1 {
  position: relative;
  width: 100%;
  margin: '10px';
}
.DraftEditor-root{
  width: 100%;
}
.suggestion-box {
  position: absolute;
  z-index: 1;
}
.editor-container .DraftEditor-root {
  width: 100%;
}
.editor {
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 10px;
  background-color: white;
  color: black;
  overflow: hidden;
  border-radius: 11px;
}

.suggestions-dropdown {
  position: absolute;
  /* top: 120px; Adjust based on your layout */
  /* left: 10px;
    right: 10px; */
  border: 1px solid #ccc;
  background-color: white;
  z-index: 10;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 350px;
  overflow-y: auto;
  border-radius: 11px;
}
.suggestion-item {
  padding: 8px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.suggestion-item:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow effect */
  background-color: #f0f0f0; /* Optional: Add a background color change */
  border-radius: 11px;
}
.suggestions-dropdown li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-dropdown li.selected {
  background-color: #f0f0f0;
}
/* .selected {
    background-color: #9c4848;
  } */

.suggestion-item.selected {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow effect */
  background-color: #e0e0e0; /* Background color to indicate selection */
}

.suggestion-item:hover,
.suggestion-item.selected:hover {
  background-color: #d0d0d0; /* Slightly darker when hovering over the selected item */
}

.function-function {
  color: #6fbb78;
  font-style: italic;
  background: #cfebd2;
  padding: 0.3rem;
  border-radius: 8px;
}
.function-othersblockIndicator {
  color: #43406f;
  background: #ccd4e8;
  padding: 0.3rem;
  border-radius: 8px;
}
.function-currentBlockIndicator {
  color: #4bb6b9;
  background: #baf0f2;
  padding: 0.3rem;
  border-radius: 8px;
}
.function-dimension {
  color:  #EB7500;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}
/* ddd */
.function-dimensionItem {
  color: #FFB000;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}
.othersblockIndicator-function {
  color: #6fbb78;
  font-style: italic;
  background: #cfebd2;
  padding: 0.3rem;
  border-radius: 8px;
}
.othersblockIndicator-othersblockIndicator {
  color: #43406f;
  background: #ccd4e8;
  padding: 0.3rem;
  border-radius: 8px;
}
.blockname-dimension{
  color:  #EB7500;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}
/* ddd */
.blockname-dimensionItem{
  color: #FFB000;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}
.currBlockIndicator-function{
  color: #6fbb78;
  font-style: italic;
  background: #cfebd2;
  padding: 0.3rem;
  border-radius: 8px;
}
.blockname-undefined{
  color: #43406f;
  background: #ccd4e8;
  padding: 0.3rem;
  border-radius: 8px;
}
.othersblockIndicator-currentBlockIndicator {
  color: #4bb6b9;
  background: #baf0f2;
  padding: 0.3rem;
  border-radius: 8px;
}
.othersblockIndicator-dimension {
  color:  #EB7500;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}
/* ddd */
.othersblockIndicator-dimensionItem {
  color: #FFB000;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}

.dimeItem-function {
  color: #6fbb78;
  font-style: italic;
  background: #cfebd2;
  padding: 0.3rem;
  border-radius: 8px;
}
.dimeItem-othersblockIndicator {
  color: #43406f;
  background: #ccd4e8;
  padding: 0.3rem;
  border-radius: 8px;
}
.dimeItem-currentBlockIndicator {
  color: #4bb6b9;
  background: #baf0f2;
  padding: 0.3rem;
  border-radius: 8px;
}
.dimeItem-dimension {
  color:  #EB7500;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}
/* ddd */
.dimeItem-dimensionItem {
  color: #FFB000;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}

.function-othersblockIndicator {
  color: #43406f;
  background: #ccd4e8;
  padding: 0.3rem;
  border-radius: 8px;
}
.function-currentBlockIndicator {
  color: #4bb6b9;
  background: #baf0f2;
  padding: 0.3rem;
  border-radius: 8px;
}

.function-undefined {
  color: #6fbb78;
  font-style: italic;
  background: #cfebd2;
  padding: 0.3rem;
  border-radius: 8px;
}

.indicator-undefined {
  color: #4bb6b9;
  background: #baf0f2;
  padding: 0.3rem;
  border-radius: 8px;
}
.blockname-othersblockIndicator {
  color: #43406f;
  background: #ccd4e8;
  padding: 0.3rem;
  border-radius: 8px;
}
.indicator-othersblockIndicator {
  color: #5f93ce;
  background: #cbe2fc;
  padding: 0.3rem;
  border-radius: 8px;
}
.currBlockIndicator-currentBlockIndicator {
  color: #4bb6b9;
  background: #baf0f2;
  padding: 0.3rem;
  border-radius: 8px;
}

.indicator-currentBlockIndicator {
  color: #4bb6b9;
  background: #baf0f2;
  padding: 0.3rem;
  border-radius: 8px;
}
.dimProperty-currentBlockIndicator{
  color: #4bb6b9;
  background: #baf0f2;
  padding: 0.3rem;
  border-radius: 8px;
}
/* ddd */

.dimItem-currentBlockIndicator{
  color: #FFB000;
  background: #baf0f2;
  padding: 0.3rem;
  border-radius: 8px;
}
/* ddd */
.dimProperty-dimensionItem{
  color: #FFB000;
  background: #baf0f2;
  padding: 0.3rem;
  border-radius: 8px;
}
/* ddd */
.dimItem-dimensionItem{
  color: #FFB000;
  background: #baf0f2;
  padding: 0.3rem;
  border-radius: 8px;
}
.currBlockIndicator-undefined {
  color: #4bb6b9;
  background: #baf0f2;
  padding: 0.3rem;
  border-radius: 8px;
}
.currBlockIndicator-othersblockIndicator {
  color: #5f93ce;
  background: #cbe2fc;
  padding: 0.3rem;
  border-radius: 8px;
}
.blockname-currentBlockIndicator {
  color: #4bb6b9;
  background: #baf0f2;
  padding: 0.3rem;
  border-radius: 8px;
}
.currBlockIndicator-dimension {
  color:  #EB7500;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}
/* ddd */
.currBlockIndicator-dimensionItem {
  color:  #FFB000;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}
.dimeItem-undefined {
  color: #eb7500;
  background: #f8ca9c;
  padding: 0.3rem;
  border-radius: 8px;
}
.dimProperty-undefined {
  color:  #EB7500;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}
.dimProperty-dimension {
  color:  #EB7500;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}

.indicator-dimension {
  color:  #EB7500;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}
/* .editor-container .custom-block {
    margin-bottom: 1rem;
  } */

.editor-container .DraftEditor-editorContainer {
  line-height: 2rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3; 
  line-clamp: 3;
  max-height: 95px;

}

.editor-container1 .DraftEditor-editorContainer {
  line-height: 2rem;
}

.function-dimensionValue {
  color:  #b18b5e;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}

.blockname-dimensionValue {
  color:  #b18b5e;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}

.indicator-dimensionValue {
  color:  #b18b5e;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}

.dimeItem-dimensionValue {
  color:  #b18b5e;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}

.dimProperty-dimensionValue {
  color:  #b18b5e;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}

.dimensionValue-dimensionValue {
  color:  #b18b5e;
  background: #fbd7ad;
  padding: 0.3rem;
  border-radius: 8px;
}