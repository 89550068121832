.user-shimmer {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  min-height: 350px;

  .user-shimmer-container {
    width: 100%;
    display: flex;
  }

  .user-shimmer-bg {
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    background-size: 1200px 100%;
  }

  @-webkit-keyframes shimmer {
    0% {
      background-position: -100% 0;
    }

    100% {
      background-position: 100% 0;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }

    100% {
      background-position: 1200px 0;
    }
  }

  .media {
    height: 130px;
  }

  .p-20 {
    padding: 0 20px;
  }

  .content-line {
    height: 9px;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 8px;
  }

  .list-content-line {
    height: 8px;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 8px;
  }

  .list-content-heading {
    height: 30px;
    width: 300px;
    margin-bottom: 40px;
    border-radius: 8px;
  }

  .user-shimmer-form-container {
    width: 70%;
  }

  .user-shimmer-form-horizontal-container {
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .user-shimmer-horizontal-field {
    width: 48%;
    border-radius: 8px;
  }
  .user-shimmer-horizontal-field-label {
    height: 10px;
    width: 100px;
    margin-bottom: 10px;
    border-radius: 8px;
  }

  .user-shimmer-horizontal-field-input {
    height: 40px;
    width: 100%;
    border-radius: 8px;
  }
  .user-shimmer-form-vertical-container {
    width: 100%;
    border-radius: 8px;
    margin-top: 15px;
  }
  .user-shimmer-vertical-field {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  .user-shimmer-vertical-field-label {
    height: 10px;
    width: 100px;
    margin-bottom: 10px;
    border-radius: 8px;
  }

  .user-shimmer-vertical-field-input {
    height: 40px;
    width: 100%;
    border-radius: 8px;
  }

  .mobile-content-line {
    height: 8px;
    width: 100%;
    margin: 15px 0;
    border-radius: 8px;
  }

  .user-shimmer-image-container {
    width: 30%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .user-shimmer-image {
    height: 140px;
    width: 150px;
    border-radius: 50%;
  }

  .user-shimmer-footer-buttons-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  .user-shimmer-image-buttons-container {
    margin-top: 1rem;
    display: flex;
    gap: 0.5rem;

    .user-shimmer-button {
      width: 100px;
      height: 30px;
      border-radius: 10px;
      margin-left: 0;
    }
  }

  .user-shimmer-button {
    width: 150px;
    height: 30px;
    border-radius: 10px;
    margin-left: 1rem;
  }

  .m-t-12 {
    margin-top: 12px;
  }

  .m-t-16 {
    margin-top: 16px;
  }
}
